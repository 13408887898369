import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { graphql } from 'gatsby'
import { Container, Row } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

export default function PrivacyPolicy({data}) {
  return (
    <Layout>
      <GatsbySeo
        titleTemplate='Terms and Conditions | %s'
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust">
            <h1>{data.allFlamelinkTermsAndConditionsContent.nodes[0].title}</h1>
          </div>
        </header>
        <section>
          <Container className="col-10 col-sm-10 col-lg-6 col-xl-5">
            <Row className="py-4">
              <ReactMarkdown plugins={[gfm]} children={data.allFlamelinkTermsAndConditionsContent.nodes[0].content} />
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allFlamelinkTermsAndConditionsContent {
    nodes {
      title
      content
    }
  }
}
`
